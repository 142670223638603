import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import axios from "../../shared/authAxios";
import {ERROR_CODES} from '../../api/api_error_codes';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AxiosAlert = () => {
  const [state, setState] = React.useState({
    open: false,
    url: "",
    msg: ""
  });

  React.useEffect(() => {
    const myInterceptor = axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {

      // Validation Failure List Response is returned
      if (error && error.response && error.response.status === ERROR_CODES.VALIDATION_FAILURE_LIST &&
        error.response.data.code === ERROR_CODES.VALIDATION_FAILURE_LIST) {
        return Promise.reject(error.response);
      }

      if (error && error.response && error.response.config) {
        let msg = error.toString()
        if (error.response.data.data) {
          if (error.response.data.data.details && error.response.data.data.details.length) {
            msg += "\n" + error.response.data.details.join(";");
          }
          else {
            msg += "\n" + error.response.data.message
          }
        } else if (error.response.data && error.response.data.message) {
          if (error.response.data.details && error.response.data.details.length) {
            msg += "\n" + error.response.data.details.join(";");
          } else {
            msg += "\n" + error.response.data.message;
          }
        }

        setState({
          open: true,
          url: error.response.config.method.toUpperCase() + " " + error.response.config.url,
          msg
        });
      } else {
        setState({
          open: true,
          url: "",
          msg: error.toString()
        });
      }
      return Promise.reject(error);

    });
    return () => {
      axios.interceptors.response.eject(myInterceptor);
    }
  }, []); // eslint-disable-line

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({
      open: false,
      url: "",
      msg: ""
    });
  };

  if (!state.open) return null;

  return (<div>
    <Snackbar open={state.open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error">
        {state.msg} ({state.url})
      </Alert>
    </Snackbar>
  </div>);
}

export default AxiosAlert;
