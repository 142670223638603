import { TimePickerConstants } from './TimePickerConstants';
import {userConstants} from "../../../views/auth/_redux/authConstants";
import moment from "moment";

const makeInitialState = () => ({
  currentDate: {
    from: moment().subtract(1, "day"),
    to: moment(),
    raw: { from: "now-1d", to: "now" }
  },
});

export function timePicker(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case TimePickerConstants.TIMEPICKER_SET_DATE:
      return {
        ...state,
        currentDate: action.currentDate
      };
    
    default:
      return state
  }
}
