import { monthlyReportsConstants } from './monthlyLegalConstants';
import {userConstants} from "../../../auth/_redux/authConstants";

const makeInitialState = () => ({
  requestActive: false,
  requestSaveActive: false,
  shouldReload: true,
  items: [],
  page_limit: 10,
  page: 1,
  total: 0,
  error: '',
  inProgress: true,
  date_start: "1970-01-01T00:00:00.000Z",
  date_end: "",
  sortDirection: "desc"
});

export function monthlyReports(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case monthlyReportsConstants.MONTHLY_REPORT_LIST_REQUEST:
      return {
        ...state,
        requestActive: true,
        shouldReload: false,
        error: '',
        total: 0,
        inProgress: true,
      };
    case monthlyReportsConstants.MONTHLY_REPORT_LIST_SUCCESS: {
      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        items: action.monthlyReports.rows ? action.monthlyReports.rows : [],
        total: action.monthlyReports.total ? action.monthlyReports.total : 0,
        inProgress: false,
      };
    }
    case monthlyReportsConstants.MONTHLY_REPORT_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };
    case monthlyReportsConstants.MONTHLY_REPORT_LIST_CHANGED:
      return {
        ...state,
        shouldReload: true,
      };
    case monthlyReportsConstants.MONTHLY_REPORT_LIST_PAGINATION: {
      let shouldReload = state.items.length === 0 ? true : false;

      if (state.rowsPerPage !== action.pagination.rowsPerPage
        || state.page !== action.pagination.page
        || state.sortBy !== action.pagination.sortBy
        || state.sortDirection !== action.pagination.sortDirection
        || state.query !== action.pagination.query
        || state.shouldReload) {
        shouldReload = true;
      }

      return {
        ...state,
        rowsPerPage: action.pagination.rowsPerPage,
        page: action.pagination.page,
        sortBy: action.pagination.sortBy,
        sortDirection: action.pagination.sortDirection,
        query: action.pagination.query,
        shouldReload
      };
    }

    case monthlyReportsConstants.MONTHLY_REPORT_SAVE_REQUEST:
      return {
        ...state,
        requestSaveActive: true,
      }
    case monthlyReportsConstants.MONTHLY_REPORT_SAVE_SUCCESS:
      if (action.isNew) {
        return {
          ...state,
          requestSaveActive: false,
          items: [...state.items, action.monthlyReport],
        }
      }
      return {
        ...state,
        requestSaveActive: false,
        items: state.items.map(t => t.id === action.monthlyReport.id ? action.monthlyReport : t)
      }
    case monthlyReportsConstants.MONTHLY_REPORT_SAVE_FAILURE:
      return {
        ...state,
        requestSaveActive: false,
        error: action.error,
      }

    default:
      return state
  }
}
